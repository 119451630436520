<template>
  <b-overlay spinner-variant="primary" :show="isLoading">
    <b-card v-for="s in services" :key="s.slug">
      <div class="d-flex align-items-center justify-content-between">
        <h4 class="mb-0">{{ s.name }}</h4>
        <b-button
          variant="outline-primary"
          :to="{ name: 'service-single', params: { slug: s.slug } }"
        >
          Редагувати
        </b-button>
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      services: [],
    };
  },
  methods: {
    async getServices() {
      this.isLoading = true;
      this.services = [];
      var result = await this.$db.getCollection({
        collectionName: "local_services",
      });
      this.services = result.docs;
      this.isLoading = false;
    },
  },
  mounted() {
    this.getServices();
  },
};
</script>

<style lang="scss" scoped></style>
